import request from "@/utils/request";

// interface User {
//     "categoryName": string,
//     categoryParentId: string | number
// }

export function reload() {
    return {
        // categoryName:'',
        // categoryParentId:''
    }
}

const baseUrl = "User/";
const userApi = {


    //人员列表
    list(current:number,data:any) {
        return request.post(`${baseUrl}GetUserPage`, {
            "orderByFiled": "Id",
            "isAsc": true,
            "offset":current,
            "limit": 20,
            "queryOptions":data
        })
    },
    //人员详情
    detail(id:string) {
        return request.get(`${baseUrl}GetUserInfo?userId=` + id)
    },

}
export default userApi;