import request from "@/utils/request";

// interface LuckyDayQuery {
//     "categoryName": string,
//     categoryParentId: string | number
// }

export function reload() {
    return {
        // categoryName:'',
        // categoryParentId:''
    }
}

const baseUrl = "LuckyDayQuery/";
const luckyDayQueryApi = {

    //吉日查询
    detail(monthParam:string,dateParam:string) {
        return request.get(`${baseUrl}GetLuckyDayQuery?monthParam=${monthParam}&dateParam=${dateParam}`)
    },

}
export default luckyDayQueryApi;