import { createRouter, createWebHistory, RouteRecordRaw,createWebHashHistory } from 'vue-router'

import LuckyDay from "@/views/PublicNumber/luckyday/luckyday.vue";
import PickUp from "@/views/Client/pickup/pickup.vue"
import Officers from "@/views/PublicNumber/officers/officers.vue";
import OfficersDetail from "@/views/PublicNumber/officersdetail/officersdetail.vue";
import OrganizationDetail from "@/views/PublicNumber/organizationdetail/organizationdetail.vue";
import MemorialHall from "@/views/Client/memorialhall/memorialhall.vue";
import Previews from "@/views/Client/previews/previews.vue";
import ClientCreate from "@/views/Client/create/create.vue";
import ArticleList from "@/views/PublicNumber/articlelist/articlelist.vue"
import ArticleDetail from "@/views/PublicNumber/articledetail/articledetail.vue";
import ServiceList from "@/views/PublicNumber/servicelist/servicelist.vue";
import Festivaldetail from "@/views/Client/festivaldetail/festivaldetail.vue";
import ServiceEvaluation from "@/views/Client/serviceevaluation/serviceevaluation.vue";
import ServiceCompany from "@/views/PublicNumber/servicecompany/servicecompany.vue";
import ServiceDetail from "@/views/PublicNumber/servicedetail/servicedetail.vue";
import AccountApply from "@/views/PublicNumber/accountapply/accountapply.vue";
import AccountList from "@/views/PublicNumber/accountlist/accountlist.vue"
import AccountDetail from "@/views/PublicNumber/accountdetail/accountdetail.vue";
import PersonapplyDetail from "@/views/PublicNumber/personapplydetail/personapplydetail.vue"
import Account from "@/views/Client/account/account.vue";
import FuneralIssues from "@/views/PublicNumber/funeralissues/funeralissues.vue";
const routes: Array<RouteRecordRaw> = [

  {
    path:'/luckyday',//吉日查询
    name:'LuckyDay',
    component:LuckyDay
  },
  {
    path:'/pickup',//领取讣告
    name:'PickUp',
    component:PickUp
  },

  {
    path:'/articlelist',//文章列表
    name:'ArticleList',
    component:ArticleList
  },
  {
    path:'/articledetail',//文章详情
    name:'ArticleDetail',
    component:ArticleDetail
  },
  {
    path:'/officers',//人员机构
    name:'Officers',
    component:Officers
  },
  {
    path:'/officersdetail',//人员详情
    name:'OfficersDetail',
    component:OfficersDetail
  },
  {
    path:'/organizationdetail',//机构列表
    name:'OrganizationDetail',
    component:OrganizationDetail
  },

  {
    path:'/',//首页
    name:'Account',
    component:Account
  },
  {
    path:'/previews',
    name:'Previews',
    component:Previews
  },
  {
    path:'/memorialhall',//纪念堂
    name:'MemorialHall',
    component:MemorialHall
  },
  {
    path:'/clientcreate',//创建
    name:'ClientCreate',
    component:ClientCreate
  },{
    path:'/festivaldetail',//祭日提醒详情
    name:'Festivaldetail',
    component:Festivaldetail
  },{
    path:'/serviceevaluation',//服务评价
    name:'ServiceEvaluation',
    component:ServiceEvaluation
  },{
    path:'/servicecompany',//服务公司
    name:'ServiceCompany',
    component:ServiceCompany
  },{
    path:'/servicelist',//服务品项列表
    name:'ServiceList',
    component:ServiceList
  },{
    path:'/servicedetail',//服务品项详情
    name:'ServiceDetail',
    component:ServiceDetail
  },{
    path:'/accountapply',//入协申请
    name:'AccountApply',
    component:AccountApply
  }
  ,{
    path:'/accountlist',//入协申请列表
    name:'AccountList',
    component:AccountList
  },{
    path:'/accountdetail',//企业入协申请详情
    name:'AccountDetail',
    component:AccountDetail
  },{
    path:'/personapplydetail',//个人入协申请详情
    name:'Personapplydetail',
    component:PersonapplyDetail
  },{
  path: '/funeralissues',//丧葬问题
    name:'FuneralIssues',
    component:FuneralIssues
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),

  routes
})

export default router
