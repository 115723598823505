import request from "@/utils/request";



export function reload() {
    return {
        categoryName:'',
        categoryParentId:''
    }
}

const baseUrl="WeChatUpload/";
const weChatUploadApi={
    //上传图片
    uploadImage(file:any){
        return request.post(`${baseUrl}UploadImage`,file)
    },
    //下载图片 ,图片流
    downloadImg(filePath:string){
        return request.get(`${baseUrl}DownLoadImg`,{FilePath:filePath},'blob')
    },
    //上传文件附件
    uploadFile(file:any){
        return request.post(`${baseUrl}UploadFile`,file)
    },
    //下载文件附件
    downLoadFile(filePath:string){
        return request.get(`${baseUrl}DownLoadFile`,{FilePath:filePath},'blob')
    },
    generateQRCode(qrcodeStr:string){
        return request.post(`${baseUrl}GenerateQRCode`, {qrcodeStr})
    },
}
export default  weChatUploadApi;