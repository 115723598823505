/* eslint-disable */
import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';

import router from '../router';
import CONFIG from '../../public/serverConfig'
import Cookies from "js-cookie";
import commMethods from "@/assets/ts/common";
// import vue_ from '../main'
// import store from '../store'
// axios.defaults.withCredentials = true;//让ajax携带cookie
const request: AxiosInstance = axios.create({
    // baseURL: '/api', // api 的 base_url 本地跨域 http://47.104.200.17:9000/api
    baseURL:process.env.NODE_ENV=== 'production' ? CONFIG.baseURL:'/api',
    timeout: 5000000 // request timeout
})
// 添加请求拦截器
// request.defaults.headers.common!["Authorization"]=commMethods.getStorage('Authorization')||''
request.interceptors.request.use((config: any) => {
    //请求统一增加请求头
    config.headers!['Content-Type'] = 'application/json'
    config.headers = {
        // 'token': Cookies.get('Token')
        openid:commMethods.getStorage('info')?.openid
        // Authorization:(commMethods.getStorage('info')?.token_type+' '+commMethods.getStorage('userInfo')?.token)||''

    }
    console.log(config)
    //删除一些不必要的查询参数
    for(const key in config.data){
        console.log(config.data[key])
        if(['tableData','loading','total'].indexOf(key)>-1||['',null,undefined].indexOf(config.data[key])>-1||config.data[key].length==0){
            delete config.data[key]
        }
    }
    console.log(config.data)
    return config;
})
//添加响应拦截器
request.interceptors.response.use(
    res => {
        console.log('res',res)
        if (res.config.responseType == 'arraybuffer') {
            console.log(res.data, res.headers)
            console.log(res)
            // return Promise.resolve(res.data)
            // let info=JSON.parse(res.headers.fileinfo);
            // console.log(info)
            // //文件异常时
            // if(info.code=='50'){
            //   return Promise.reject(Object.assign(res.data,info))
            // }
            if (res.headers.retcode == "S505")
                return Promise.resolve(Object.assign(res.data, res.headers))
            else {
                return Promise.reject(res.data)
            }
        }
        if (res.config.responseType == 'blob') {
            console.log('blob',res)
            return Promise.resolve(res.data)
        }
        if(res.data.status!==0){

            // if(res.data.code=='10010002'){
            //         localStorage.clear();
            //         router.push('/');
            // }
          return Promise.reject(res.data)
        }
        else{
          return Promise.resolve(res.data)
        }
        // //成功返回
        // if (['success','S505','S506','S507','U200','D003','D005'].indexOf(res.data.code)>-1) {
        //   // if(['S505','S506','S507'].indexOf(res.data.code)>-1){
        //   //   ElMessage.success(res.data.msg)
        //   // }
        //
        //   // 返回data
        //   return Promise.resolve(res.data)
        // } else {
        //   if (['U204','U205'].indexOf(res.data.code)>-1) {
        //     localStorage.clear();
        //     router.push('/login');
        //   }
        //   // //没有权限
        //   // if (res.data.code == '3') {
        //   //   // store.state.refreshMenu = true;
        //   // }
        //   ElMessage.error(res.data.msg);
        //   return Promise.reject(res.data)
        // }
        // return response.data
    },
        error => {
        //失败返回，方法返回一个带有拒绝原因reason参数的Promise对象。
        console.log(error)
            if(error.response.data.status==401){
                commMethods.relogin();
            }
        return Promise.reject(error)
    })


function get(url: string, data?: any, responseType?: any) {
    return request.get(url, {
        params: data,
        responseType: responseType
    })
}
function del(url: string, data?: any, responseType?: any) {
    return request.delete(url, {
        params: data,
        responseType: responseType,

    })
}

function post(url: string, data?: any, responseType?: any) {
    return request.post(url, data, {responseType: responseType})
}
function put(url: string, data?: any, responseType?: any) {
    return request.put(url, data, {responseType: responseType})
}
export default {
    get, post,del,put
}