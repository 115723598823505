import request from "@/utils/request";

interface AnsweringQuestion{
    "questionTitle": string,
    categoryParentId:string|number
}

export function reload() {
    return {
        questionTitle:'',
        // categoryParentId:''
    }
}

const baseUrl="AnsweringQuestion/";
const answeringQuestionApi={
    //列表
    list(pageData: any,params:AnsweringQuestion){
        return request.post(`${baseUrl}GetAnsweringQuestionPage`,{
            "offset": pageData.current,
            "limit": pageData.size,
            "orderByFiled": "Id",
            "isAsc": true,
            queryOptions: {...params}
        })
    },
    //详情
    detail(id:string|number){
        return request.get(`${baseUrl}GetAnsweringQuestion?id=`+id)
    },
    //更改  0 新增 1 编辑
    updateOrAdd(type: number, data: any) {
        return request[type ?"put":"post"](baseUrl + (type ? 'UpdateAnsweringQuestion' : 'CreateAnsweringQuestion'), data)
    },
    //删除
    del(id:string|number){
        return request.del(`${baseUrl}RemoveAnsweringQuestion?id=`+id)
    },
    pageList(current:number,params:AnsweringQuestion){
        return request.post(`${baseUrl}GetAnsweringQuestionList`,{
            "offset": current,
            "limit": 50,
            "orderByFiled": "Id",
            "isAsc": true,
            queryOptions: {...params}
        })
    }

}
export default  answeringQuestionApi;