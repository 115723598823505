
import store from "@/store";
import weChatApi from "@/utils/Api/WeChat";
// import { Base64 } from "js-base64";
import wx from 'weixin-js-sdk'
import CONFIG from "../../../public/serverConfig";


// const _data={
//   appid:'wxf7f8feac463160c9',
//   // appid:'wx23e0b50a33848bd9',
//   appsecret:'1c069f7bd210697c1c89c2340523faa8',
//   scope:'snsapi_userinfo',//应用授权作用域，snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo （弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 ）
//   timestamp:new Date().getTime(),
//   nonceStr:'Wm3WZYTPz0wzccnW'
// }
const commMethods = {

  //todo 存储到本地
  getStorage(name: string) {
    const data = localStorage.getItem(name);
    if (typeof data === "string") return JSON.parse(data);
  },
  setStorage(name: string, data: any) {
    const _data = JSON.stringify(data);
    localStorage.setItem(name, _data);
  },
  removeStorage(name: string) {
    localStorage.removeItem(name);
  },
  relogin(){
    localStorage.clear();
    const local =window.location.href.substring(0,window.location.href.indexOf('?'));
    window.location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid='+CONFIG.appid+'&redirect_uri='+encodeURIComponent(local)+'&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';

  },
  getTicket(jsApiList:any){
    const d=commMethods.getStorage('info');
    weChatApi.getJsApiTicketSignature(location.href.split('#')[0]).then(res=>{
      console.log(222,res)
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: CONFIG.appid, // 必填，公众号的唯一标识
        timestamp:  res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr:   res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature,// 必填，签名
        jsApiList: jsApiList // 必填，需要使用的JS接口列表
      })
    })
  },

  //todo 页面渲染初始值
  loadData(currentPage=1,pageSize=20){
    return{
      total:0,
      loading:true,
      size:pageSize,
      current:currentPage,
      // tableData:[],
      tableData:[{
        date: "2016-05-03",
        name: "Tom",
        address: "No. 189, Grove St, Los Angeles",
      },
      {
        date: "2016-05-02",
        name: "Tom",
        address: "No. 189, Grove St, Los Angeles",
      },
      {
        date: "2016-05-04",
        name: "Tom",
        address: "No. 189, Grove St, Los Angeles",
      },{
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },{
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },{
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },{
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },{
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },{
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },{
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },{
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },{
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },{
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },{
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },{
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },]
    }
  },

  formatterTime(time:Date=new Date(),tp='minute') {
    if(!time){
      return ''
    }
    const _t=new Date(time);
    const year = _t.getFullYear()
    const month = (_t.getMonth() + 1)>9? (_t.getMonth() + 1):('0'+_t.getMonth())// 由于月份从0开始，因此需加1
    const day = _t.getDate()>9? _t.getDate():('0'+_t.getDate())
    const hour = _t.getHours()>9? _t.getHours():('0'+_t.getHours())
    const minute = _t.getMinutes()>9? _t.getMinutes():('0'+_t.getMinutes())
    const second = _t.getSeconds()>9? _t.getSeconds():('0'+_t.getSeconds())

    switch (tp){
      case 'second':
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      case 'minute':
        return `${year}-${month}-${day} ${hour}:${minute}`;
    }
    // if(type=='second')
    // return `${year}-${month}-${day} ${hour}:${minute}:${second}`
  },
  //新增修改数据通用方法
  getListData(idx:number,list:any=[],data:any){
    if(idx===-1)
      list.push(data)
    else{
      list.splice(idx,1,data)
    }
  },
  //上下移动数据 list 数据列表  type 0上移 1下移 2移动到目标位置  idx当前数据位置  idx2目的位置
  moveData(list:any,type:number,idx:number,idx2?:number){
    const nData=list[idx];
    if(type==0){
      list.splice(idx,1);
      list.splice(idx-1,0,nData);
    }else if(type==1){
      list.splice(idx,1);
      list.splice(idx+1,0,nData);
    }else{
      list.splice(idx,1);
      list.splice(idx2,0,nData);
    }
  },
  getDialogText(text:string,type:number){
    if(type==0)
      return '新增'+text;
    else if (type==1)
      return '编辑'+text;
    else
      return text+'详情';
  },
  /**
   * @description: 文件下载
   * @param {Blob} blob 参数1：blob对象
   * @param {string} name 参数2：文件名称，包含文件后缀
   * @return {*}
   */
  download (blob:any, name:string) {
    const link = document.createElement('a'); //创建一个a标签
    const url = URL.createObjectURL(blob); //将blob文件对象通过URL.createObjectURL()方法转为为url
    link.href = url; //为a标签设置href属性，并赋值为url
    link.download = name; //定义下载的文件名，文件名要包含后缀哟！如'导出EXCEL.xlsx'
    document.body.appendChild(link); //把a标签放在body上
    link.click(); //出发a标签点击下载
    document.body.removeChild(link); //在body中移除这个a标签
    URL.revokeObjectURL(url); //释放blob对象
  },
  downloadWithName(res:any,text:string,fileType='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
    console.log('downloadWithName',res)
    if(!res) return "导出失败！";
    const blob = new Blob([res], {type: fileType}) // 构造一个blob对象来处理数据，并设置文件类型

    // const blob = new Blob([res], {type: info.fileType}) // 构造一个blob对象来处理数据，并设置文件类型
    // if (window.navigator.msSaveOrOpenBlob) { //兼容IE10
    //   // navigator.msSaveBlob(blob,Base64.decode(info.fileName));
    //   navigator.msSaveBlob(blob,'文件1');
    // } else {
    const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
    const a = document.createElement('a') //创建a标签
    a.style.display = 'none'
    a.href = href // 指定下载链接
    // a.download = Base64.decode(text)//指定下载文件名
    a.download=text;
    a.click() //触发下载
    URL.revokeObjectURL(a.href) //释放URL对象
    // }
  },


};
export default commMethods;
