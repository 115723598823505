
const obj:any={
    resourceType:[{label:'目录',value:'catalog'},{label:'菜单',value:'menu'},{label:'按钮',value:'button'}],//菜单类型
    isOpen:[{label:'启用',value:1},{label:'停用',value:0}],
    sex:[{label:'男',value:'男'},{label:'女',value:'女'}],
    //启用停用2
    isOpen2:[{label:'启用',value:0},{label:'停用',value:1}],
    //题目类型
    quTypes: [{value: 1, label: '单选题'}, {value: 2, label: '多选题'}, {value: 3, label: '判断题'}],
    btnType:[{label:'button',value:'button'},{label:'link',value:'link'}],
    btnColorType:[{label:'primary',value:'primary'},{label:'default',value:'default'},{label:'danger',value:'danger'},{label:'success',value:'success'},{label:'info',value:'info'}],//系统按钮类型
    colFixedType:[{label:'left',value:'left'},{label:'right',value:'right'}],
    colAlignType:[{label:'left',value:'left'},{label:'right',value:'right'}],
    operateType:[{label:'弹窗',value:'popup'},{label:'提示',value:'tips'},{label:'确认消息',value:'confirm'}],
    btnFuncType:[{label:'查询',value:'search'},{label:'重置',value:'reset'},{label:'新增',value:'add'}],
    timeDateType:[{label:'日期',value:'date'},{label:'日期范围',value:'daterange'},{label:'日期时间',value:'datetime'},{label:'日期时间范围',value:'datetimerange'}
        ,{label:'年',value:'year'} ,{label:'周',value:'week'},{label:'月',value:'month'},{label:'多个日期',value:'dates'}],
    dateFormat:[{label:'YYYY-MM-DD',value:'YYYY-MM-DD'},{label:'YYYY/MM/DD',value:'YYYY/MM/DD'}],
    daterangeFormat:[{label:'YYYY-MM-DD',value:'YYYY-MM-DD'},{label:'YYYY/MM/DD',value:'YYYY/MM/DD'}],
    datetimeFormat:[{label:'YYYY-MM-DD HH:mm:ss',value:'YYYY-MM-DD HH:mm:ss'},{label:'YYYY/MM/DD HH:mm:ss',value:'YYYY/MM/DD HH:mm:ss'},],
    datetimerangeFormat:[{label:'YYYY-MM-DD HH:mm:ss',value:'YYYY-MM-DD HH:mm:ss'},{label:'YYYY/MM/DD HH:mm:ss',value:'YYYY/MM/DD HH:mm:ss'},],
    yearFormat:[{label:'YYYY',value:'YYYY'},{label:'YYYY年',value:'YYYY年'}],
    monthFormat:[{label:'YYYY-MM',value:'YYYY-MM'},{label:'YYYY/MM',value:'YYYY/MM'},{label:'YYYY年MM月',value:'YYYY年MM月'}],
    weekFormat:[{label:'ww',value:'ww'},{label:'ww周',value:'ww周'},{label:'YYYY年MM月',value:'YYYY年MM月'}],
    datesFormat:[{label:'YYYY-MM-DD',value:'YYYY-MM-DD'},{label:'YYYY/MM/DD',value:'YYYY/MM/DD'}],
    formType:[{label:'输入框',value:'input'},{label:'下拉框',value:'selectOption'},{label:'单选框',value:'radioOption'},{label:'数字区间',value:'range'},{label:'日期时间',value:'dateTime'}],
    fieldType:[{label:'string',value:'string'},{label:'number',value:'number'},{label:'boolean',value:'boolean'},{label:'array',value:'array'},{label:'object',value:'object'}],
    //输入框类型选项
    inputTypeOpt:[{label:'文本',value:'text'},{label:'文本域',value:'textarea'},{label:'密码',value:'password'},{label:'数字',value:'number'}],
    //是否选项
    yesOrNoOpt:[{label:'是',value:true},{label:'否',value:false}],
    yesOrNoOpt2:[{label:'是',value:1},{label:'否',value:0}],
    //用户是否可以缩放
    autoFocusOpt:[{label:'否',value:'none'},{label:'全部',value:'both'},{label:'水平',value:'horizontal'},{label:'垂直',value:'vertical'}],
    //数字控制按钮选项
    controlsPositionOpt:[{label:'right',value:'right'}],
    //dialog编辑状态文字
    dialogEditTextOpt:[{label:'新增',value:'0'},{label:'编辑',value:'1'},{label:'查看',value:'2'}],

    //考试人范围 所有人 指定学生
    testPersonArea:[{label:'所有人',value:1},{label:'指定学生',value:3}],
    //参考状态
    referenceStatus:[{label:'未考',value:0},{label:'已考',value:1}],


    //审核状态
    approvalStatus:[{label:'待审核',value:0},{label:'已通过',value:2},{label:'未通过',value:3}],
    //会员状态
    memberStatus:[{label:'有效',value:true},{label:'过期',value:false}],

    //会员、公司类型
    merchantCategoryStatus:[{label:'企业',value:2},{label:'墓园',value:3},{label:'殡仪馆',value:4}],
    //是否公示
    publicStatus:[{label:'是',value:true},{label:'否',value:false}],
    evaluateStatus:[{label:'审核通过',value:true},{label:'待审核',value:false}],

    //一级固定分类  ,{label:'治丧问答',value:28161365627468118}
    categoryList:[{label:'行业动态',value:28113144514257932},{label:'殡葬新闻',value:28161365535340865},{label:'政策公示',value:28161467316671127},{label:'协会公告',value:28168195359550340}],

}

type ColorType={
    type:''|'success'|'info'|'danger'|'warning',
    effect:'dark' | 'light' | 'plain',
    value:number
}
const classifyColor:ColorType[]=[{
    type:'',
    value:1,
    effect:'dark'
},{
    type:'success',
    value:2,
    effect:'dark'
},{
    type:'info',
    value:3,
    effect:'dark'
},{
    type:'danger',
    value:4,
    effect:'dark'
},{
    type:'warning',
    value:5,
    effect:'dark'
},{
    type:'',
    value:6,
    effect:'light'
},{
    type:'success',
    value:7,
    effect:'light'
},{
    type:'info',
    value:8,
    effect:'light'
},{
    type:'danger',
    value:9,
    effect:'light'
},{
    type:'warning',
    value:10,
    effect:'light'
},{
    type:'',
    value:11,
    effect:'plain'
},{
    type:'success',
    value:12,
    effect:'plain'
},{
    type:'info',
    value:13,
    effect:'plain'
},{
    type:'danger',
    value:14,
    effect:'plain'
},{
    type:'warning',
    value:15,
    effect:'plain'
}]
const dic={
    getDicText(type: any, val: any){
        let _tx='';
        const t=obj[type]
        for(let i=0;i<t.length;i++){
            if(t[i].value==val){
                _tx=t[i].label;
                break;
            }
        }
        return _tx;
    },
    getDicList(type:string){
        return obj[type]
    },
    getAllList(){
        return[
            {label:'菜单类型', value:'resourceType'},
            {label:'启用停用', value:'isOpen'},
            {label:'性别', value:'sex'},
            {label:'合同状态', value:'contractState'},
            {label:'标签类型', value:'labelType'},
        ]
    },
    getTagColorList(){
        return classifyColor
    },
    getTagColor(value:number){
        let _c;
        for(const i in classifyColor){
            if(classifyColor[i].value==value){
                _c=classifyColor[i];
                break;
            }
        }
        return _c
    }
}
export default dic