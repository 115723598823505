import request from "@/utils/request";

// interface User {
//     "categoryName": string,
//     categoryParentId: string | number
// }

export function reload() {
    return {
        // categoryName:'',
        // categoryParentId:''
    }
}

const baseUrl = "News/";
const newsApi = {


    //人员列表
    list(current:number,data:any) {
        return request.post(`${baseUrl}GetNewsPage`, {
            "orderByFiled": "Id",
            "isAsc": true,
            "offset":current,
            "limit": 20,
            "queryOptions":data
        })
    },
    //人员详情
    detail(id:string) {
        return request.get(`${baseUrl}GetNewsDetail?articleId=` + id)
    },

}
export default newsApi;