// baseURL IP:端口为Nginx配置的前端地址
const CONFIG = {
  baseURL:'https://merchant.dqhmbz.com/api',
appid:'wxf7f8feac463160c9',
  uploadImgUrl:'https://merchant.dqhmbz.com/api',
  //图片服务器地址
  imgURL:'https://image.dqhmbz.com'
}


// const CONFIG = {
//   baseURL:'http://47.104.200.17:9010/api',
//   appid:'wx23e0b50a33848bd9',
//   uploadImgUrl:'http://47.104.200.17:9010/api',
//   //图片服务器地址
//   imgURL:'https://image.dqhmbz.com',
// }
// const CONFIG = {
//   baseURL:'http://127.0.0.1:9000/api',
//   appid:'wx23e0b50a33848bd9',
//   uploadImgUrl:'http://127.0.0.1:9000/api',
//   //图片服务器地址
//   imgURL:'https://image.dqhmbz.com',
// }

export default CONFIG;