import request from "@/utils/request";

interface WeChatMerchantMember{
    "id": string,
    // categoryParentId:string|number
}

export function reload() {
    return {
        // questionTitle:'',
        // categoryParentId:''
    }
}

const baseUrl="WeChatMerchantMember/";
const weChatMerchantMemberApi={
    //列表
    list(pageData: any,params:WeChatMerchantMember){
        return request.post(`${baseUrl}GetMerchantMemberPage`,{
            "offset": pageData.current,
            "limit": pageData.size,
            "orderByFiled": "Id",
            "isAsc": true,
            queryOptions: {...params}
        })
    },
    //详情
    detail(id:string|number){
        return request.get(`${baseUrl}GetMerchantMember?id=`+id)
    },
    //更改  0 新增 1 编辑
    updateOrAdd(type: number, data: any) {
        return request[type ?"put":"post"](baseUrl + (type ? 'UpdateMerchantMember' : 'CreateMerchantMember'), data)
    },
    //删除
    del(id:string|number){
        return request.del(`${baseUrl}RemoveMerchantMember?id=`+id)
    }

}
export default  weChatMerchantMemberApi;