import request from "@/utils/request";

// interface Organization {
//     "categoryName": string,
//     categoryParentId: string | number
// }

export function reload() {
    return {
        // categoryName:'',
        // categoryParentId:''
    }
}

const baseUrl = "Organization/";
const organizationApi = {
    //机构列表
    list(current:number,data:any) {
        return request.post(`${baseUrl}GetMerchantMemberPage`, {
            "orderByFiled": "Id",
            "isAsc": true,
            "offset":current,
            "limit": 20,
            "queryOptions":data
        })
    },
    //机构详情
    detail(id:string) {
        return request.get(`${baseUrl}GetMerchantMember?memberId=` + id)
    },

}
export default organizationApi;