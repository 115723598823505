
import { createApp } from 'vue'

import App from './App.vue'


import router from './router'
import store from './store'
import Vant from 'vant'

import 'vant/lib/index.css';

import './assets/css/common.scss'

import jssdk from 'weixin-js-sdk'
createApp(App).use(Vant).use(store).use(router).use(jssdk).mount('#app')
