import request from "@/utils/request";
import commMethods from "@/assets/ts/common";

interface Obituary {
    "categoryName": string,
    categoryParentId: string | number
}

export function reload() {
    return {
        // categoryName:'',
        // categoryParentId:''
    }
}

const baseUrl = "WeChat/";
const weChatApi = {
    //获取微信网页授权
    getAccessTokenCode(code:string) {
        return request.get(`${baseUrl}GetAccessTokenCode?code=` + code)
    },
    //获取jssdk中jsapi_ticket
    getJapiTicket(){
        return request.get(`${baseUrl}GetJapiTicket` )
    },
    //jssdk签名
    getJsApiTicketSignature(url:string){
        return request.get(`${baseUrl}GetJsApiTicketSignature`,{Url:url})
    },
    //家属端讣告以及相关信息
    getObituaryUserList(){
      return request.post(`${baseUrl}GetObituaryUserList`,{
          openId:commMethods.getStorage('info')?.openid
      })
    },

    //获取微信全局授权
    getAccessToken(){
        return request.get(`${baseUrl}GetAccessToken`)

    },
    //服务评价
    /**
     *  "obituaryId": "string",
     *   "merchantId": "string",
     *   "evaluateStar": "string",
     *   "merchantCustomerId": "string",
     *   "wechatUserId": "string",
     *   "createTime": "2024-07-23T17:31:42.008Z",
     *   "isDeleted": true,
     *   "evaluateContent": "string",
     *   "evaluateStatus": true,
     *   "score": 0
     */
    createServiceEvaluation(data:any){
        return request.post(`${baseUrl}CreateServiceEvaluation`,data)
    },
    //评价服务商家
    // "merchantId": "string",
    // "obituaryId": "string"
    getServiceMerchantDetail(data:any){
        return request.post(`${baseUrl}GetServiceMerchantDetail`,data)
    },
    //新增微信用户
    /**
     * {
     *   "id": 0,
     *   "nickname": "string",
     *   "headimg": "string",
     *   "sex": 0,
     *   "openid": "string",
     *   "unionid": "string",
     *   "province": "string",
     *   "city": "string",
     *   "country": "string",
     *   "createtime": "2024-07-23T17:32:10.811Z",
     *   "isDeleted": true
     * }
     */
    createWeChatUser(data:any){
        return request.post(`${baseUrl}CreateWeChatUser`,data)
    },
    //获取当前微信用户信息
    getWeChatUserDetail(){
        return request.get(`${baseUrl}GetWeChatUserDetail`)
    },
    //祭日提醒列表
    deathList(current:number,data:any) {
        return request.post(`${baseUrl}GetDeathinfoSacrificialDayPage`, {
            "orderByFiled": "Id",
            "isAsc": true,
            "offset":current,
            "limit": 20,
            "queryOptions":data
        })
    },

    //祭日提醒详情
    deathDetail(id:string) {
        return request.get(`${baseUrl}GetDeathinfoSacrificialDayDetail?id=` + id)
    },

    //品相分类公司列表
    getMerchantmemberCategoryList(cateId:string,companyName:string) {
        // return request.get(`${baseUrl}GetMerchantmemberCategoryList?cateId=` + cateId )


        return request.post(`${baseUrl}GetMerchantmemberCategoryList` ,{cateId,companyName} )

    },
    //品相列表（分页）
    getServiceItemPage(current:number,data:any){
        return request.post(`${baseUrl}GetServiceItemPage`, {
            "orderByFiled": "Id",
            "isAsc": true,
            "offset":current,
            "limit": 20,
            "queryOptions":data
        })
    },
    //品相详情
    getServiceItemDetail(serviceItemId:string) {
        return request.get(`${baseUrl}getServiceItemDetail?serviceItemId=` + serviceItemId )
    },
    //根据微信openId返回领取讣告信息
    getObituaryDetailByOpenId(){
        return request.get(`${baseUrl}GetObituaryDetailByOpenId`,{openId:commMethods.getStorage('info')})
    },

//详情
    detail(id:string) {
        return request.get(`${baseUrl}GeObiturayDetail?id=` + id)
    },
    //讣告视图详情
    view(id:string){
        return request.get(`${baseUrl}GeObiturayDetail?weChatId=` + id)
    },
    //更改  0 新增 1 编辑
    updateOrAdd(type:number, data:any) {
        return request[type ? "put" : "post"](baseUrl + (type ? 'UpdateObituary' : 'CreateObituary'), data)
    },
    //删除
    del(id:string) {
        return request.del(`${baseUrl}RemoveObituary?id=` + id)
    },
    //转发讣告
    share(id:string){
        return request.get(`${baseUrl}ObituaryShare?weChatId=` + id)
    },
    /**
     客户领取讣告
     "id": 0,
     "weChatId": 0,
     "merchantId": 0,
     "wechatUserId": 0,
     "receivePhone": "string",
     "createTime": "2024-07-17T10:52:34.175Z",
     "merchantCustomerId": 0
     **/
    receiveObituary(data: any) {
        return request.post(`${baseUrl}ReceiveObituary`, data)
    },
    // 讣告详情商家信息
    getObituaryMerchant(weChatId: string) {
        return request.get(`${baseUrl}GetObituaryMerchant?weChatId=`+weChatId)
    },
    //发布讣告留言
    createObituaryMessage(data: any) {
        return request.post(`${baseUrl}CreateObituaryMessage`, data)
    },
    //讣告详情留言列表
    getObituaryMessge(current: number,weChatId:string) {
        return request.post(`${baseUrl}GetObituaryMessge`, {
            "orderByFiled": "Id",
            "isAsc": true,
            "offset":current,
            "limit": 20,
            "queryOptions": {
                weChatId
            }
        })
    },
    //获取讣告背景音乐
    getBackgroundMusicList(){
        return request.get(`${baseUrl}GetBackgroundMusicList` )
    },
    //讣告开关
    /**
     * "id": "string",
     * "isrelease": true
     */
    updateSysObituaryRelease(data:any){
        return request.post(`${baseUrl}UpdateSysObituaryRelease`,data)
    },
}
export default weChatApi;