import request from "@/utils/request";

interface WeChatMemorialHall {
    "categoryName": string,
    categoryParentId: string | number
}

export function reload() {
    return {
        // categoryName:'',
        // categoryParentId:''
    }
}

const baseUrl = "WeChatMemorialHall/";
const weChatMemorialHallApi = {
    //获取讣告纪念堂详情
    detail(obituaryid:string) {
        return request.post(`${baseUrl}GetMemorialHallDetail`,{obituaryid})
    },
    //讣告纪念堂留言
    getMemorialHallMessagePage(current:number,memorialHallId:string) {
        return request.post(`${baseUrl}GetMemorialHallMessagePage`,{
            "orderByFiled": "Id",
            "isAsc": true,
            "offset":current,
            "limit": 200,
            "queryOptions":{memorialHallId}
        })
    },
//新增纪念堂留言
    create(data:any){
        return request.post(`${baseUrl}CreateMemorialHallMessage`,
            // {
            //   "memorialHallid": 0,
            //   "memorialhallid": 0,
            //   "messagecontent": "string",
            //   "userid": 0,
            // }
            data
        )
    },
    //纪念堂上香
    createMemorialBurningIncense(data:any){
        // {
        //   "id": 0,
        //   "memorialhallid": 0,
        //   "obituaryid": 0,
        //   "total": 0
        // }
        return request.post(`${baseUrl}createMemorialBurningIncense`,data)
    },
    //纪念堂上香统计
    getMemrialHallBurningIncenseTotal(id:string){
        return request.get(`${baseUrl}GetMemrialHallBurningIncenseTotal?memrialHallId=` + id)
    },
    //纪念堂上贡
    createMemorialPaytribute(data:any){
        // {
        //   "id": 0,
        //   "memorialhallid": 0,
        //   "obituaryid": 0,
        //   "total": 0
        // }
        return request.post(`${baseUrl}CreateMemorialPaytribute`,data)
    },
    //纪念堂上贡统计
    getPaytributeCount(id:string){
        return request.get(`${baseUrl}GetPaytributeCount?memrialHallId=` + id)
    },
    //纪念堂清扫
    createMemorialHallSweep(data:any){
        // {
        //   "id": 0,
        //   "memorialhallid": 0,
        //   "obituaryid": 0,
        //   "total": 0
        // }
        return request.post(`${baseUrl}CreateMemorialHallSweep`,data)
    },
    //纪念堂清扫统计
    getMemrialHallSweepTotal(id:string){
        return request.get(`${baseUrl}GetMemrialHallSweepTotal?memrialHallId=` + id)
    },
}
export default weChatMemorialHallApi;